

















import TeamInformation from "@/components/shared/TeamInformation.vue";
import Moment from "@/services/moment";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { getTeamSessionsForTeam } from "@/api/teamSessionService";
import { userModule } from "@/store/modules/user";
import Learnlink from "@learnlink/interfaces";
import TeamCardHeader from "@/components/shared/TeamCardHeader.vue";
import useAsyncData from "@/hooks/useAsyncData";
import CalendarCourse from "../shared/CalendarCourse.vue";

export default defineComponent({
  name: "TeamCardStudent",
  components: {
    TeamInformation,
    TeamCardHeader,
    CalendarCourse
  },
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    },
  },
  setup(props) {
    const { data: dates } = useAsyncData(() => getTeamSessionsForTeam(props.team.ID));
    const account = computed(() => userModule.state.account);
    const sellerName = computed(() => props.team.verboseSellers[0]?.firstName);
    const sessionsLeft = computed(() => dates.value?.filter((session) => session.startTime > Moment().unix()).length || 12);

    const newTeamMessages = computed((): number => {
      const lastRead = userModule.state.user.lastReadTeamMessages?.filter(o => o.teamID === props.team.ID)[0];
      if (!lastRead) return props.team.information?.length || 0;
      return props.team.information?.filter(message => message.created > lastRead.read || 0).length;
    });

    return {
      account,
      newTeamMessages,
      sellerName,
      sessionsLeft,
    };
  },
});
