















































import CustomerDialog from "@/components/seller/my-teams/CustomerDialog.vue";
import { defineComponent, PropType, reactive } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "ParentInformation",
  components: { CustomerDialog },
  props: {
    parent: {
      type: Object as PropType<Learnlink.Customer.ForVerboseTeam>,
      required: true,
    },
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    },
    unreadMessages: {
      type: Object as PropType<Record<string, number>>,
      required: true,
    },
  },
  setup() {
    const vm = reactive({
      customerDialogVisible: false,
      selectedCustomerUID: "",
    });

    function openCustomerDialog(parentUID: string) {
      vm.selectedCustomerUID = parentUID;
      vm.customerDialogVisible = true;
    }

    return {
      openCustomerDialog,
      vm,
    };
  },
});
