




































































































































































































































import ContractDialog from "@/components/seller/my-teams/ContractDialog.vue";
import GettingStarted from "@/components/shared/GettingStarted.vue";
import { capitalize } from "@/helpers/stringUtils";
import {
  computed,
  defineComponent,
  onUnmounted,
  PropType, provide, reactive, ref, watch,
} from "@vue/composition-api";
import { userModule } from "@/store/modules/user";
import Learnlink from "@learnlink/interfaces";
import ProfilePhoto from "@/components/profile/ProfilePhoto.vue";
import TeamCardDialogs from "@/components/shared/TeamCardDialogs.vue";
import Moment from "@/services/moment";
import useAsyncData from "@/hooks/useAsyncData";
import { listCourseSessions } from "@/api/courseService";

export default defineComponent({
  name: "TeamCardHeader",
  components: {
    ContractDialog,
    GettingStarted,
    TeamCardDialogs,
    ProfilePhoto,
  },
  props: {
    sessionsLeft: {
      type: Number as PropType<number>,
      default: 16,
    },
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      showContractDialog: false,
    });
    const sellerName = computed(() => `${props.team.verboseSellers[0]?.firstName} ${props.team.verboseSellers[0]?.lastName}`);
    const courseStartsAt = ref();
    const courseSession = useAsyncData(() => listCourseSessions(props.team.course.ID));

    const readableTime = computed(() => {
      const weeks = props.team.course.weeks;
      const teamSessions = props.team.teamSessions;
      const sessions = courseSession.data.value;

      if (props.team.course.location === "calendar") {
        if (!sessions || !sessions.length) return "Ingen datoer satt ennå";

        const lockedSesisons = sessions.filter((session) => session.locked);
        if (!lockedSesisons.length) return "Alt er åpent 🎉";

        return `${lockedSesisons.length} kalenderluker igjen!`;
      }

      if (!teamSessions.length) return "Ingen datoer satt ennå";
      const teamFromTime = Moment.unix(teamSessions[0].startTime).format("HH:mm");
      const teamToTime = Moment.unix(teamSessions[0].endTime).format("HH:mm");
      const teamFromDate = Moment.unix(teamSessions[0].startTime).format("D. MMMM");
      const teamToDate = Moment.unix(teamSessions[teamSessions.length - 1].startTime).format("D. MMMM");

      const weekday = capitalize(`${Moment.unix(teamSessions[0].startTime).format("dddd")}er`);

      if (weeks > 1) return `${weekday} kl. ${teamFromTime} - ${teamToTime}, ${teamFromDate} - ${teamToDate}`;
      return `Kl. ${teamFromTime} - ${teamToTime}, ${teamFromDate} - ${teamToDate}`;
    });

    function openURL(url: string): void {
      const win = window.open(url, "_self");
      win?.focus();
    }

    let interval: number;
    const createTimer = () => {
      if (interval) clearInterval(interval);

      if (props.team.activated) {
        courseStartsAt.value = "Ingen datoer satt ennå";
        return;
      }

      if (props.team.course.location === "calendar" && courseSession.isLoading.value === true) {
        courseStartsAt.value = "Laster inn datoer...";
        return;
      }
      // Set the date we're counting down to
      let countDownDate: number | undefined;
      if (props.team.course.location !== "calendar" && props.team.teamSessions[0]) {
        countDownDate = new Date(props.team.teamSessions[0].startTime * 1000).getTime();
      }
      else if (props.team.course.location === "calendar") {
        const lockedSesisons = courseSession.data.value?.filter((session) => session.locked).sort((a, b) => {
          const date1 = new Date(`${a.open!.date} ${a.open!.time}`);
          const date2 = new Date(`${b.open!.date} ${b.open!.time}`);
          return date1.getTime() - date2.getTime();
        });

        if (!lockedSesisons) {
          courseStartsAt.value = "Oops! Noe gikk galt.";
          return;
        }

        if (!lockedSesisons.length) {
          courseStartsAt.value = "Alt er åpent 🎉";
          return;
        }

        const latestSession = lockedSesisons[0];
        countDownDate = new Date(`${latestSession.open!.date} ${latestSession.open!.time}`).getTime();
      }

      const update = () => {
        if (!countDownDate) return;

        // Get today's date and time
        const now = new Date().getTime();

        // Find the distance between now and the count down date
        const distance = countDownDate - now;

        if (distance <= 0) {
          courseStartsAt.value = "...";
          return;
        }

        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        const prefix = props.team.course.location === "calendar" ? "Neste kalenderluke om" : "";

        if (days >= 1) {
          // format to 1.december
          const time = new Date(countDownDate);
          const formattedDate = time.toLocaleDateString("no-NO", { day: "numeric", month: "long" });

          courseStartsAt.value = `${prefix} ${formattedDate}`;
          return;
        }

        courseStartsAt.value = `${prefix} ${hours}t ${minutes}m ${seconds}s`;
      };
      // Update the countdown every 1 second
      setInterval(update, 1000);
      update();
    };

    watch([courseSession.isLoading, courseSession.data, props.team], createTimer);
    createTimer();
    onUnmounted(() => {
      if (interval) clearInterval(interval);
    });

    const myStudents = computed(() => {
      return props.team.verboseStudents.filter((student) => student.parentUID === userModule.state.userId);
    });

    function closeContract() {
      vm.showContractDialog = false;
    }

    function openContract() {
      vm.showContractDialog = true;
    }

    const signedContract = computed(() => {
      return props.team.contractsSigned.length > 0;
    });

    provide("closeContract", closeContract);

    const currentSession = computed(() => {
      if (!props.team.teamSessions.length) return null;

      const team = props.team;

      const nowTime = Moment.unix(Math.floor(Date.now() / 1000)) / 1000;
      const sortedSessions = team.teamSessions.sort((a, b) => a.startTime - b.startTime);

      let session = null;
      for (let i = 0; i < sortedSessions.length; i++) {
        const teamSession = sortedSessions[i];
        if (teamSession.startTime > nowTime) {
          session = teamSession;
          break;
        }
      }

      return session;
    });

    return {
      closeContract,
      isParent: computed(() => userModule.state.user.role === "parent"),
      isSeller: computed(() => userModule.state.user.role === "seller"),
      isStudent: computed(() => userModule.state.user.role === "student"),
      isCalendar: computed(() => props.team.course.location === "calendar"),
      myStudents,
      openContract,
      openURL,
      courseSession,
      readableTime,
      sellerName,
      signedContract,
      currentSession,
      courseStartsAt,
      vm,
    };
  },
});
