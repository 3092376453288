import Learnlink from "@learnlink/interfaces";
import learnlinkAPI from "./learnlinkAPI";

const ENDPOINT = "/app/messages";

export async function send(message: Learnlink.Message.Create): Promise<Learnlink.Message.FullViewApp> {
  const res = await learnlinkAPI.axios.post<Learnlink.Message.FullViewApp>(
    `${ENDPOINT}/`,
    message
  );
  return res.data;
}

export async function list(ownUID: string, otherUID: string): Promise<Learnlink.Message.FullViewApp[]> {
  const res = await learnlinkAPI.axios.get<Learnlink.Message.FullViewApp[]>(
    `${ENDPOINT}/${ownUID}/${otherUID}`
  );
  return res.data;
}

export async function markRead(ID: string): Promise<Learnlink.Message.default> {
  const res = await learnlinkAPI.axios.put<Learnlink.Message.default>(
    `${ENDPOINT}/${ID}/read`
  );
  return res.data;
}

export async function getUnreadMessagesForCustomer(ownUID: string, otherUID: string): Promise<number> {
  const res = await learnlinkAPI.axios.get<number>(
    `${ENDPOINT}/unreadCount/${ownUID}/${otherUID}`
  );
  return res.data;
}

export async function getUnreadMessagesForSellerForTeam(teamID: string, ownUID: string): Promise<Record<string, number>> {
  const res = await learnlinkAPI.axios.get<Record<string, number>>(
    `${ENDPOINT}/unreadCountForTeam/${teamID}/${ownUID}`
  );
  return res.data;
}
