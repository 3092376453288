

















import { computed, defineComponent, onMounted, PropType } from "@vue/composition-api";
import { markRead } from "@/api/feedbackService";
import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";

export default defineComponent({
  name: "FeedbackCard",
  props: {
    feedback: {
      type: Object as PropType<Learnlink.Feedback.View.App.Pro.FeedbackCard>,
      required: true,
    },
  },
  setup(props) {
    const readableTime = computed(() =>
      Moment.unix(props.feedback.created).format("Do MMMM YYYY HH:mm")
    );

    onMounted(async () => {
      if (!props.feedback.read) {
        await markRead(props.feedback.ID);
      }
    });

    return {
      readableTime
    };
  }
});
