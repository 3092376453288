





















import StudentCard from "@/components/customer/my-students-customer/StudentCard.vue";
import { computed, defineComponent, inject, PropType } from "@vue/composition-api";
import { userModule } from "@/store/modules/user";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "MyTeamMembers",
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    },
  },
  components: { StudentCard },
  setup(props) {
    const myStudents = computed(() => {
      return props.team.verboseStudents.filter((student) => student.parentUID === userModule.state.userId);
    });

    const openAddStudentDialog = inject<() => void>("openAddStudentDialog");

    return {
      openAddStudentDialog,
      myStudents,
    };
  },
});
