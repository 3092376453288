














import { defineComponent, PropType, ref, watchEffect } from "@vue/composition-api";
import TeamContract from "@/components/seller/my-teams/TeamContract.vue";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "ContractDialog",
  components: {
    TeamContract
  },
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    return {
      _value
    };
  }
});
