


















import { defineComponent, PropType } from "@vue/composition-api";
import analytics from "@/services/analytics";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "OpenZoomButton",
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    },
  },
  setup(props) {
    function openZoom(): void {
      analytics.track("openZoom", {
        URL: props.team.overrideRoomURL,
      });
      const win = window.open(props.team.overrideRoomURL || "https://zoom.us/test", "_blank");
      win?.focus();
    }

    return {
      openZoom,
    };
  },
});
