













































































import YouHaveNewDiploma from "@/components/student/YouHaveNewDiploma.vue";
import { computed, defineComponent } from "@vue/composition-api";
import { getMyActiveTeams } from "@/api/teamService";
import { userModule } from "@/store/modules/user";
import Moment from "@/services/moment";
import ProfilePhoto from "@/components/profile/ProfilePhoto.vue";
import SupportPhoneAlert from "@/components/SupportPhoneAlert.vue";
import TeamCardStudent from "@/components/student/TeamCardStudent.vue";
import Uploader from "@/components/settings/upload/Uploader.vue";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  name: "StudentDashboard",
  components: {
    YouHaveNewDiploma,
    ProfilePhoto,
    SupportPhoneAlert,
    TeamCardStudent,
    Uploader,
  },
  setup() {
    const { isLoading, data } = useAsyncData(() => getMyActiveTeams(userModule.state.userId));

    async function updateProfile(newPhotoURL: string): Promise<void> {
      await userModule.actions.updateUserProfile({ photoURL: newPhotoURL });
    }

    const filteredTeams = computed(() => data.value?.filter(team => {
      if (team.course.location === "calendar") {
        return true;
      }
      return !team.previousTeamID || team.teamSessions[0].startTime < Moment().add(1, "week").unix();
    }) ?? []);

    return {
      filteredTeams,
      isLoading,
      profile: computed(() => userModule.state.profile),
      updateProfile,
    };
  },
});
