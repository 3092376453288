





























import { defineComponent, PropType } from "@vue/composition-api";
import Credentials from "@/components/Credentials.vue";
import Learnlink from "@learnlink/interfaces";
import ProfilePhoto from "@/components/profile/ProfilePhoto.vue";

export default defineComponent({
  name: "StudentInformation",
  components: { ProfilePhoto, Credentials },
  props: {
    student: {
      type: Object as PropType<Learnlink.Student.View.App.ForTeam>,
      required: true,
    },
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    },
  },
});
