
























































































































































































































































import { getTeamSessionsForTeam } from "@/api/teamSessionService";
import ConnectDiscordButton from "@/components/ConnectDiscordButton.vue";
import MyTeamMembers from "@/components/customer/MyTeamMembers.vue";
import OpenZoomButton from "@/components/OpenZoomButton.vue";
import useAsyncData from "@/hooks/useAsyncData";
import analytics from "@/services/analytics";
import Moment from "@/services/moment";
import {
  computed,
  defineComponent, onBeforeUnmount,
  onMounted,
  PropType,
  provide,
  reactive,
} from "@vue/composition-api";
import { getUnreadFeedback } from "@/api/feedbackService";
import { getUnreadMessagesForCustomer, getUnreadMessagesForSellerForTeam } from "@/api/messageService";
import { userModule } from "@/store/modules/user";
import CourseContent from "@/components/courses/CourseContent.vue";
import CustomerFeedbackToSeller from "@/components/seller/my-feedback/CustomerFeedbackToSeller.vue";
import Learnlink from "@learnlink/interfaces";
import Messages from "@/components/Messages.vue";
import TeamContract from "@/components/seller/my-teams/TeamContract.vue";
import TeamMembersSeller from "@/components/seller/my-teams/TeamMembersSeller.vue";

export default defineComponent({
  name: "TeamCardDialogs",
  components: {
    ConnectDiscordButton,
    CourseContent,
    CustomerFeedbackToSeller,
    Messages,
    MyTeamMembers,
    OpenZoomButton,
    TeamContract,
    TeamMembersSeller,
  },
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      dialogComponent: "",
      dialogVisible: false,
      isParent: userModule.state.user.role === "parent",
      isSeller: userModule.state.user.role === "seller",
      newPrivateMessages: 0,
      unreadFeedback: 0,
      unreadMessages: {} as Record<string, number>,
    });

    let interval: number;

    onMounted(async () => {
      fetchUnreadMessages();
      if (vm.isSeller) fetchUnreadFeedback();

      // Fetch new messages every 2 minutes.
      interval = setInterval(() => {
        fetchUnreadMessages();
      }, 240000);

      // Clear new message fetching after 60 minutes,
      // in case someone leaves the browser open.
      setTimeout(() => {
        clearInterval(interval);
      }, 2700000);
    });

    onBeforeUnmount(() => {
      clearInterval(interval);
    });

    async function fetchUnreadFeedback(): Promise<void> {
      vm.unreadFeedback = await getUnreadFeedback(props.team.ID);
    }

    async function fetchUnreadMessages(): Promise<void> {
      if (userModule.state.user.role === "seller") {
        vm.newPrivateMessages = 0;
        vm.unreadMessages = await getUnreadMessagesForSellerForTeam(props.team.ID, userModule.state.userId);

        Object.values(vm.unreadMessages).forEach((val) => {
          vm.newPrivateMessages += val;
        });
      }
      else {
        vm.newPrivateMessages = await getUnreadMessagesForCustomer(userModule.state.userId, props.team.sellers[0]);
      }
    }

    function openDialog(dialogComponent: "contract" | "feedback" | "info" | "members" | "members-customer" | "course-content" | "messages" | "performance") {
      vm.dialogComponent = dialogComponent;
      vm.dialogVisible = true;
      analytics.track("openDialog", {
        component: dialogComponent,
      });
    }

    function openURL(url: string): void {
      const win = window.open(url, "_blank");
      win?.focus();
    }

    const sellerName = computed(() => props.team.verboseSellers[0]?.firstName);

    const { data: dates } = useAsyncData(() => getTeamSessionsForTeam(props.team.ID));
    const sessionsLeft = computed(() => dates.value ? dates.value.filter((session) => session.startTime > Moment().unix()).length : 12);
    const teamHasStarted = computed(() => props.team.teamSessions.length ? props.team.teamSessions[0].startTime < Moment().unix() : false);

    provide("fetchUnreadFeedback", fetchUnreadFeedback);
    provide("fetchUnreadMessages", fetchUnreadMessages);

    return {
      openDialog,
      openURL,
      sellerName,
      sessionsLeft,
      teamHasStarted,
      vm,
    };
  },
});
