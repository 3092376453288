



























































import { getTeamSettings } from "@/api/platformSettingsService";
import { computed, defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import { createLinks } from "@/helpers/stringUtils";
import { userModule } from "@/store/modules/user";
import Credentials from "@/components/Credentials.vue";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "GettingStarted",
  components: {
    Credentials,
  },
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      location: {} as Learnlink.PlatformSettings.Location,
    });

    onMounted(async () => {
      const locations = await getTeamSettings();
      vm.location = locations.locations.filter((location) => props.team.location === location.ID)[0];
    });

    const minecraftCourse = computed(() => props.team.course.title.toLowerCase().includes("minecraft"));
    const role = userModule.state.user.role;

    function openGoogleMaps(URL: string): void {
      window.open(URL, "_blank");
    }

    const students = computed(() => {
      if (userModule.state.user.role === "parent") {
        return props.team.verboseStudents.filter((student) => {
          return student.parentUID === userModule.state.userId;
        });
      }
      else {
        return props.team.verboseStudents.filter((student) => {
          return student.uid === userModule.state.userId;
        });
      }
    });

    return {
      createLinks,
      minecraftCourse,
      openGoogleMaps,
      role,
      students,
      vm,
    };
  },
});
