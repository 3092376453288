





























































































































































































































































import { computed, defineComponent, inject, PropType, reactive } from "@vue/composition-api";
import { signContract } from "@/api/teamService";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import handleError from "@/helpers/errors";
import Moment from "@/services/moment";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "TeamContract",
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    },
  },
  setup(props) {
    const getMyTeams = inject<() => Promise<void>>("getMyTeams");
    const closeContract = inject<() => void>("closeContract");

    const vm = reactive({
      loading: false,
    });

    const notify = useNotifier();

    const fullName = userModule.getters.getFullName();
    const dateTime = Moment().format("Do MMM YYYY HH:mm");

    const firstSessionReadableDate = computed(() => {
      if (props.team.teamSessions) {
        return Moment.unix(props.team.teamSessions[0].startTime).format("Do MMM YYYY");
      }
      return 0;
    });

    const lastSessionReadableDate = computed(() => {
      if (props.team.teamSessions) {
        return Moment.unix(props.team.teamSessions[props.team.teamSessions.length - 1].startTime).format("Do MMM YYYY");
      }
      return 0;
    });

    const signatureTime = computed(() => {
      const signatures = props.team.contractsSigned?.filter((signature) => signature.uid === userModule.state.userId);
      return signatures?.length > 0 ? Moment.unix(signatures[0].signed).format("Do MMM YYYY HH:mm") : 0;
    });

    const signatureLine = computed(() => `${fullName}, ${dateTime}`);

    async function sign() {
      vm.loading = true;

      try {
        await signContract(props.team.ID, userModule.state.userId);
        if (getMyTeams) await getMyTeams();
        if (closeContract) closeContract();
        notify({
          title: "Signert!",
          message: "Kontrakten er signert.",
          type: "success",
        });
      }
      catch (e) {
        handleError(e);
      }

      vm.loading = false;
    }

    return {
      firstSessionReadableDate,
      fullName,
      lastSessionReadableDate,
      signatureTime,
      sign,
      signatureLine,
      vm,
    };
  },
});
