





import Learnlink from "@learnlink/interfaces";
import { PropType, defineComponent } from "@vue/composition-api";
import CourseContent from "../courses/CourseContent.vue";

export default defineComponent({
  name: "CalendarCourse",
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    }
  },
  components: { CourseContent }
});

