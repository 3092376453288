

















































import { getStudent } from "@/api/customerService";
import { listFiles, setDiplomaRead } from "@/api/fileService";
import { userModule } from "@/store/modules/user";
import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import fx from "fireworks";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "YouHaveNewDiploma",
  props: {
    uid: {
      type: String as PropType<string>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      diploma: {} as Learnlink.File.Reference,
      diplomaDialogVisible: false,
      innerHeight: 0,
      innerWidth: 0,
      name: "",
      step: 0,
    });

    function close(): void {
      vm.diplomaDialogVisible = false;
    }

    onMounted(async () => {
      vm.innerHeight = window.innerHeight;
      vm.innerWidth = window.innerWidth;

      const diplomas = await listFiles("diplomas", props.uid);

      const unseenDiplomas = diplomas.filter((diploma) => !diploma.seen);

      if (unseenDiplomas.length) vm.diploma = unseenDiplomas[0];

      if (props.uid === userModule.state.userId) vm.name = "Du";
      else {
        const student = await getStudent(props.uid);
        vm.name = student.profile?.firstName || "";
      }

      if (vm.diploma.url) {
        vm.diplomaDialogVisible = true;
      }
    });

    async function openDiploma(): Promise<void> {
      vm.step++;
      fireworksLoop();
      setTimeout(fireworksLoop, 200);
      await setDiplomaRead(props.uid, vm.diploma.uid);
    }

    function fireworksLoop(): void {
      fx({
        x: Math.random() * vm.innerHeight,
        y: Math.random() * vm.innerWidth,
        canvasWidth: 150,
        count: 60,
        colors: ["#29cdff", "#78ff44", "#ff718d"],
      });

      if (vm.diplomaDialogVisible) setTimeout(fireworksLoop, 400);
    }

    return {
      close,
      openDiploma,
      vm,
    };
  },
});
