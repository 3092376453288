















import { userModule } from "@/store/modules/user";
import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "ConnectDiscordButton",
  setup() {
    const vm = reactive({
      dialog: false,
    });

    async function connect() {
      window.open("https://discord.gg/JDSYuYZSpf");
    }

    return {
      connect,
      userModule,
      vm,
    };
  },
});
