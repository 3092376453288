

























































import ParentInformation from "@/components/seller/my-teams/ParentInformation.vue";
import StudentInformation from "@/components/seller/my-teams/StudentInformation.vue";
import { defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import { getDiplomasForTeam } from "@/api/fileService";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "TeamMembers",
  components: { ParentInformation, StudentInformation },
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.View.FullMetadata>,
      required: true,
    },
    unreadMessages: {
      type: Object as PropType<Record<string, number>>,
      required: true,
    },
  },
  setup(props) {
    const vm = reactive({
      diplomas: {} as Record<string, string>,
    });

    function getStudents(parentUID: string): Learnlink.Student.View.App.ForTeam[] {
      return props.team.verboseStudents.filter(student => student.parentUID === parentUID);
    }

    onMounted(async () => {
      await mount();
    });

    async function mount() {
      vm.diplomas = await getDiplomasForTeam(props.team.ID);
    }

    return {
      getStudents,
      mount,
      vm,
    };
  },
});
